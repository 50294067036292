function Title({ children }) {
  return <div className="w-full shadow-md">
    <div className="w-full h-[1px] from-transparent via-white to-transparent bg-gradient-to-r opacity-[.15]"></div>
    <div className="flex h-[4rem] items-center justify-center from-transparent via-transparent-white to-transparent bg-gradient-to-r">
      <h1 className="font-semibold text-xl">{children}</h1>
    </div>
    <div className="w-full h-[1px] from-transparent via-white to-transparent bg-gradient-to-r opacity-[.15]"></div>
  </div>;
}

export default Title;