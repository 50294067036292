import i18next from '../i18n'

function getRelativeTime(date) {
  const now = new Date();
  const diffInSeconds = Math.floor((date - now) / 1000);

  const rtf = new Intl.RelativeTimeFormat('fr', { numeric: 'auto' });

  const units = [
    { unit: 'year', seconds: 31536000 },
    { unit: 'month', seconds: 2592000 },
    { unit: 'week', seconds: 604800 },
    { unit: 'day', seconds: 86400 },
    { unit: 'hour', seconds: 3600 },
    { unit: 'minute', seconds: 60 },
    { unit: 'second', seconds: 1 },
  ];

  for (const { unit, seconds } of units) {
    if (Math.abs(diffInSeconds) >= seconds) {
      const value = Math.ceil(diffInSeconds / seconds);
      return rtf.format(value, unit);
    }
  }

  return i18next.t('time.now');
}

export default getRelativeTime;