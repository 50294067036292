function Button({ children, gradient=false, className }) {
  return (
    <button
      className={
        `px-4 sm:px-8 h-12 shadow-sm hover:shadow-md transition rounded-full text-md ${className} 
        ${gradient
          ?'text-primary bg-gradient-to-r from-pastel-yellow to-pastel-purple font-semibold border-0 transform hover:-translate-y-1 hover:shadow-outline'
          : 'bg-secondary text-white font-regular border border-outline'}`
        }
    >
      {children}
    </button>
  );
}

export default Button;